import { LifeCycle, WixCodeSdkHandlersProviderSym } from '@wix/thunderbolt-symbols'
import {
	ComponentLibraries,
	ComponentsLoaderRegistry,
	ComponentLoaderFunction,
	ThunderboltHostAPI,
	ComponentLibrary,
	CompController,
	CreateCompControllerArgs,
	CompControllersRegistry,
	ComponentsRegistry,
	UpdateCompProps,
} from './types'
import { ComponentsLoaderSymbol } from './symbols'
import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { ComponentsLoaderInit } from './componentsLoaderInit'
import { ComponentsLoader } from './componentsLoader'
import { IComponentsLoader } from './IComponentLoader'
import { controlledComponentFactory } from './updateControlledComponentProps'

// Public loader
export const site: ContainerModuleLoader = (bind, bindAll) => {
	bind(LifeCycle.AppWillMountHandler).to(ComponentsLoaderInit)
	bind(ComponentsLoaderSymbol).to(ComponentsLoader)
	bindAll([LifeCycle.AppWillMountHandler, WixCodeSdkHandlersProviderSym], controlledComponentFactory)
}

// Public Symbols
export { ComponentsLoaderSymbol }

// Public Types
export {
	IComponentsLoader,
	ComponentLibraries,
	ComponentLibrary,
	ComponentsLoaderRegistry,
	ComponentLoaderFunction,
	ThunderboltHostAPI,
	CompController,
	CreateCompControllerArgs,
	CompControllersRegistry,
	ComponentsRegistry,
	UpdateCompProps,
}
