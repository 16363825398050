import React from 'react'

import { withDependencies } from '@wix/thunderbolt-ioc'
import { AppStructure, ComponentLibrariesSymbol } from '@wix/thunderbolt-symbols'
import {
	ComponentsLoaderRegistry,
	ComponentsRegistry,
	ComponentLibraries,
	ThunderboltHostAPI,
	ComponentLoaderFunction,
	CompControllersRegistry,
} from './types'
import { IComponentsLoader } from './IComponentLoader'
import { taskify } from '@wix/thunderbolt-commons'

type ComponentsLoaderFactory = (componentsLibraries: ComponentLibraries) => IComponentsLoader

const getCompClassType = (componentType: string, uiType?: string) =>
	uiType ? `${componentType}_${uiType}` : componentType

const componentsLoaderFactory: ComponentsLoaderFactory = (componentsLibraries) => {
	const componentsLoaderRegistry: ComponentsLoaderRegistry = {}
	const componentsRegistry: ComponentsRegistry = {}
	const compControllersRegistry: CompControllersRegistry = {}

	const loadComponent = async (compType: string) => {
		const loader = componentsLoaderRegistry[compType]
		if (!loader) {
			return
		}

		const module = await taskify(() => loader())
		componentsRegistry[compType] = componentsRegistry[compType] || React.memo(module.component || module.default)
		if (module.controller) {
			compControllersRegistry[compType] = module.controller
		}
	}

	const getRequiredComps = (structure: AppStructure) => {
		const allCompClassTypes = Object.entries(structure).map(([_, { componentType, uiType }]) =>
			getCompClassType(componentType, uiType)
		)
		const uniqueCompTypes = [...new Set(allCompClassTypes)]
		return uniqueCompTypes
	}

	const hostAPI: ThunderboltHostAPI = {
		registerComponent: <Props>(
			componentType: string,
			loadingFunction: ComponentLoaderFunction<Props>,
			uiType?: string
		) => {
			const compClassType = getCompClassType(componentType, uiType)
			if (process.env.NODE_ENV === 'development' && componentsLoaderRegistry[compClassType]) {
				console.warn(
					`${compClassType} was already registered. Please remove it from thunderbolt components ASAP`
				)
			}
			componentsLoaderRegistry[compClassType] = loadingFunction
		},
	}

	// ORDER MATTERS!!!
	const registerLibraries = taskify(async () => {
		const libs = await componentsLibraries()
		return libs.reduce(
			(acc, library) =>
				acc.then(async () => {
					const registerComponents = await library
					await taskify(() => registerComponents(hostAPI))
				}),
			Promise.resolve()
		)
	})

	return {
		getComponentsMap: () => componentsRegistry,
		getCompControllersMap: () => compControllersRegistry,
		loadComponents: async (structure) => {
			await registerLibraries

			const requiredComps = getRequiredComps(structure)
			return Promise.all(requiredComps.map((compType) => loadComponent(compType)))
		},
		loadComponent: async (componentType: string, uiType?: string) =>
			loadComponent(getCompClassType(componentType, uiType)),
	}
}

export const ComponentsLoader = withDependencies([ComponentLibrariesSymbol], componentsLoaderFactory)
