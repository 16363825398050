import { withDependencies } from '@wix/thunderbolt-ioc'
import { IAppWillMountHandler, IContext, Context, BrowserWindowSymbol, BrowserWindow } from '@wix/thunderbolt-symbols'
import { isSSR } from '@wix/thunderbolt-commons'
import { ComponentsLoaderSymbol } from './symbols'
import { IComponentsLoader } from './IComponentLoader'

const componentsLoaderInit = (
	context: IContext,
	componentsLoader: IComponentsLoader,
	window: BrowserWindow
): IAppWillMountHandler => {
	return {
		async appWillMount() {
			context.extend({
				comps: componentsLoader.getComponentsMap(),
				compControllers: componentsLoader.getCompControllersMap(),
			})
			// load wix custom-elemtns as part of all the components loader
			// triggered after DOM ready and when app will mount
			if (!isSSR(window) && window!.initCustomElements) {
				window!.initCustomElements()
			}
		},
	}
}

export const ComponentsLoaderInit = withDependencies(
	[Context, ComponentsLoaderSymbol, BrowserWindowSymbol],
	componentsLoaderInit
)
