import { ComponentsLoaderRegistry, ComponentLibrary } from '@wix/thunderbolt-components-loader'
import { componentsLoaders } from 'thunderbolt-components-react'

export type TBElementsCompLibrary = {
	registerComponents: ComponentLibrary
}

// https://github.com/Microsoft/TypeScript/issues/26781
// @ts-ignore
export const tbElementComponents = async (tbElementsPromise: Promise<TBElementsCompLibrary>): ComponentLibrary => {
	const tbElements = await tbElementsPromise
	const registerComponents = await tbElements.registerComponents
	return (hostAPI) => {
		registerComponents(hostAPI)
	}
}

// https://github.com/Microsoft/TypeScript/issues/26781
// @ts-ignore
export const thunderboltComponents = async (): ComponentLibrary => {
	return (hostAPI) => {
		Object.entries(componentsLoaders as ComponentsLoaderRegistry).forEach(([compType, loadComponent]) => {
			const [componentType, uiType] = compType.split('_')
			hostAPI.registerComponent(componentType, loadComponent, uiType)
		})
	}
}
