import { IThunderbolt } from '../features/thunderbolt/IThunderbolt'
import { Thunderbolt } from '../features/thunderbolt/types'
import { createEnvLoader } from '../features/env'
import { Container, IocContainer } from '@wix/thunderbolt-ioc'
import { Environment } from '../types/Environment'
import {
	IPageAssetsLoader,
	PageAssetsLoaderSymbol,
	MasterPageFeatureConfigSymbol,
	BIReporter,
	ILogger,
} from '@wix/thunderbolt-symbols'
import { DynamicSessionModelSymbol, DynamicSessionModel } from 'feature-session-manager'
import { taskify } from '@wix/thunderbolt-commons'

export type ThunderboltInitPayload = Environment

export type InitThunderbolt = () => Promise<IThunderbolt>

export type InitThunderboltContainer = (
	options: ThunderboltInitPayload,
	container?: IocContainer
) => Promise<InitThunderbolt>

const loadEnvironment = (container: IocContainer, environment: Environment): void =>
	container.load(createEnvLoader(environment))

const loadMasterPageFeaturesConfigs = async (container: IocContainer) => {
	// This adds the master page structure and props to the fetchCache
	const assetsLoader = container.get<IPageAssetsLoader>(PageAssetsLoaderSymbol)
	const siteFeaturesConfigs = await assetsLoader.load('masterPage').siteFeaturesConfigs

	Object.entries(siteFeaturesConfigs).forEach(([featureName, featureConfig]) => {
		container
			.bind(MasterPageFeatureConfigSymbol)
			.toConstantValue(featureConfig)
			.whenTargetNamed(featureName)
	})
}

const loadDynamicModel = async (
	container: IocContainer,
	{ biReporter, logger }: { biReporter: BIReporter; logger: ILogger }
) => {
	const dynamicModelRaw = await window.fetchDynamicModel
	if (typeof dynamicModelRaw === 'object') {
		container.bind<DynamicSessionModel>(DynamicSessionModelSymbol).toConstantValue(dynamicModelRaw)
		const { visitorId, siteMemberId } = dynamicModelRaw
		biReporter.setDynamicSessionData({ visitorId, siteMemberId })
	} else {
		logger.captureError(new Error(dynamicModelRaw), { tags: { fetchFail: 'dynamicModel' } })
	}
}

export const initThunderboltContainer: InitThunderboltContainer = async (
	options: ThunderboltInitPayload,
	container: IocContainer = new Container()
) => {
	loadEnvironment(container, options)

	const { viewerModel, specificEnvFeaturesLoaders } = options

	await taskify(() => specificEnvFeaturesLoaders.loadSiteFeatures(container, viewerModel.siteFeatures))

	return async () => {
		await taskify(() => loadMasterPageFeaturesConfigs(container))

		if (process.env.browser) {
			await taskify(() =>
				loadDynamicModel(container, {
					biReporter: options.biReporter,
					logger: options.logger,
				})
			)
		}

		const thunderbolt = await taskify(() => container.get<IThunderbolt>(Thunderbolt))

		await taskify(() => thunderbolt.ready())

		return thunderbolt
	}
}
